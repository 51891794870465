import React from 'react'
import ReactDOM from 'react-dom'
import reportWebVitals from './reportWebVitals'
import * as Sentry from '@sentry/browser'
import { Integrations } from '@sentry/tracing'

import './index.css'
import App from './App'
import { version } from '../package.json'

if (process.env.NODE_ENV === 'production') {
  Sentry.init({
    dsn: 'https://cc728e809b354a1bb2287ff1909208df@o901209.ingest.sentry.io/5842106',
    integrations: [new Integrations.BrowserTracing()],
    tracesSampleRate: 0.7,
    release: version,
  })
}

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
