// import AppBar from '@material-ui/core/AppBar'
// import Toolbar from '@material-ui/core/Toolbar'
import { makeStyles } from "@material-ui/core"
import Typography from '@material-ui/core/Typography'

import Footer from './Footer'
import Navbar from './Navbar'

const useStyles = makeStyles((theme) => {
  const { palette, spacing, sideSpace } = theme
  return {
    root: {
      display: 'flex',
      flexDirection: 'column',
      minHeight: '100vh'
    },
    content: {
      flexGrow: 1
    },
    appBar: {
      boxShadow: 'none',
      background: 'none'
    },
    header: {
      backgroundColor: palette.secondary.main,
      display: 'flex',
      [theme.breakpoints.down('xs')]: {
        flexDirection: 'column',
      },
      justifyContent: 'space-between',
      padding: `${spacing(1)}px ${sideSpace}`,
    },
    image: {
      [theme.breakpoints.down('sm')]: {
        width: '250px',
      },
      width: '300px',
    },
    heading: {
      padding: `${spacing(4)}px ${sideSpace}`,
    }
  }
})

function Layout({ children }) {
  const classes = useStyles()

  return (
    <div className={classes.root}>
      <div className={classes.content}>
        <header className={classes.header}>
          {/* <AppBar
            position='static'
            color='default'
            className={classes.appBar}
          > */}
          {/* <div className={classes.toolbar}> */}
          <a href='https://www.fulcrum3d.com' target='_blank' rel='noreferrer'>
            <img src='logo_f3d_white_font.png' alt='' className={classes.image} style={{ marginTop: '8px' }} />
          </a>
          <a href='https://www.statedevelopment.qld.gov.au/' target='_blank' rel='noreferrer'>
            <img src='logo_qld.svg' alt='' className={classes.image} />
          </a>
          {/* </div> */}
          {/* </AppBar> */}
        </header>
        <div className={classes.heading}>
          <Typography variant='h4'>Salisbury Plains Wind & Solar Resource Monitoring</Typography>
        </div>
        <Navbar />
        {children}
      </div>
      <Footer />
    </div>
  )
}

export default Layout
