import PropTypes from 'prop-types'
// material
import { CssBaseline, isWidthUp } from '@material-ui/core'
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles'

const theme = createMuiTheme({
  palette: {
    primary: {
      // main: '#10B4CC'
      main: '#02CCFF'
    },
    secondary: {
      main: '#005E85',
    },
    action: {
      // hover: '#C3D5D7'
      hover: '#02CCFF',
    }
  },
  typography: {
    fontFamily: [
      'Roboto',
      'Montserrat',
      // 'Poppins',
    ].join(','),
    h4: {
      fontFamily: 'Poppins',
      // fontFamily: 'Montserrat',
      fontSize: '28px',
    },
    h5: {
      fontFamily: 'Poppins',
      // fontFamily: 'Montserrat',
    },
    h6: {
      fontFamily: 'Poppins',
      // fontFamily: 'Montserrat',
    },
  },
  sideSpace: isWidthUp('sm') ? '7%' : '5%',
})

function ThemeConfig({ children }) {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline>
        {children}
      </CssBaseline>
    </ThemeProvider>
  )
}

ThemeConfig.propTypes = {
  children: PropTypes.node
}

export default ThemeConfig
