import { makeStyles } from '@material-ui/core'
import Typography from '@material-ui/core/Typography'

import { version } from '../../package.json'

const useStyle = makeStyles(theme => ({
  footer: {
    display: 'flex',
    justifyContent: 'left',
    flexShrink: 0,
    padding: theme.spacing(2),
    backgroundColor: theme.palette.secondary.main,
  },
  text: {
    marginLeft: theme.sideSpace,
    color: 'white',
  },
  f3d: {
    color: theme.palette.primary.main,
    textDecoration: 'none',
    marginLeft: '4px',
    marginRight: '4px',
  }
}))

function Footer() {
  const classes = useStyle()
  return (
    <footer className={classes.footer}>
      <Typography className={classes.text} >
        © 2016-{(new Date()).getFullYear()}
        <a
          href='https://www.fulcrum3d.com'
          target='_blank'
          rel='noreferrer'
          className={classes.f3d}>
          Fulcrum3D
        </a> v{version}
      </Typography>
    </footer>
  )
}

export default Footer
