import { Suspense, lazy } from 'react'
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom'
import DateFnsUtils from '@date-io/date-fns'
import { MuiPickersUtilsProvider } from '@material-ui/pickers'
import * as am4core from '@amcharts/amcharts4/core'

import Home from './pages/Home'
import ThemeConfig from './theme'
import Layout from './components/Layout'
import { DateRangeProvider } from './context/DateRange'

const TimeSeries = lazy(() => import('./pages/TimeSeries'))
const Aggregation = lazy(() => import('./pages/Aggregation'))
const Download = lazy(() => import('./pages/Download'))
const About = lazy(() => import('./pages/About'))

am4core.options.minPolylineStep = 1
am4core.options.queue = true
am4core.options.onlyShowOnViewport = false

function App() {
  return (
    <ThemeConfig>
      <Router>
        <Suspense fallback={<Layout><Home /></Layout>}>
          <Layout>
            <Switch>
              <DateRangeProvider>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <Route exact={true} path={['/', '/wind', '/time-series']} component={TimeSeries} />
                  <Route exact={true} path='/aggregation' component={Aggregation} />
                  <Route exact={true} path='/download' component={Download} />
                  <Route exact={true} path='/about' component={About} />
                </MuiPickersUtilsProvider>
              </DateRangeProvider>
            </Switch>
          </Layout>
        </Suspense>
      </Router>
    </ThemeConfig>
  )
}

export default App
