import { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
// material
import { makeStyles, Paper } from '@material-ui/core'
// 
import NavButton from './NavButton'

const useStyles = makeStyles(theme => {
  const { spacing, sideSpace } = theme
  return {
    navbar: {
      margin: `${spacing(1)}px ${sideSpace} ${spacing(2)}px ${sideSpace}`,
      [theme.breakpoints.down('xs')]: {
        display: 'flex',
        flexDirection: 'column',
        padding: spacing(1),
      },
      padding: spacing(2),
      paddingLeft: spacing(4),
      borderRadius: '12px',
    },
  }
})

function Navbar() {
  const classes = useStyles()

  const location = useLocation()

  const [menu, setMenu] = useState(location.pathname.split('/')[1])

  useEffect(() => {
    setMenu(location.pathname.split('/')[1])
  }, [location])

  const handleClick = e => {
    setMenu(e.currentTarget.name)
  }

  return (
    <Paper className={classes.navbar} variant='outlined' elevation={1}>
      <NavButton to='/time-series' handleClick={handleClick} name='time series data' isActive={['wind', 'time-series', ''].includes(menu)} />
      <NavButton to='/aggregation' handleClick={handleClick} name='aggregated data' isActive={menu === 'aggregation'} />
      <NavButton to='/download' handleClick={handleClick} name='download' isActive={menu === 'download'} />
      <NavButton to='/about' handleClick={handleClick} name='about' isActive={menu === 'about'} />
    </Paper>
  )
}

export default Navbar
